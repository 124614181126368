<template>
  <div>
    <f-default-header :title="$t('pages.mkt.index.title')" :subtitle="$t('pages.mkt.items.subtitle')" />

    <f-list />

    <f-actions-btn-holder>
      <v-btn color="primary" elevation="0" large @click="create">
        {{ $t("labels.btnNemItem") }}
      </v-btn>
    </f-actions-btn-holder>
  </div>
</template>

<script>
import FList from "../../../components/mkt/items/List.vue";

export default {
  components: {
    FList,
  },
  async created() {
    this.list();
  },
  methods: {
    create() {
      this.$router.push({
        name: "mkt-items-create",
      });
    },
    async list() {
      this.$store.dispatch("system/loading", "data");
      await this.$store.dispatch("mkt/items_list");
      this.$store.dispatch("system/loading", "done");
    },
  },
};
</script>
