export default {
  'es': {
    1: 'Destacado',
    2: 'Vertical',
    3: 'Evento',
    4: 'Banco de Imágenes',
    5: 'Materiales',
    6: 'Calendario de Marketing',
    7: 'Institucional',
    8: 'Herramientas de Ventas',
    9: 'Apertura de Tickets',
  },
  'en': {
    1: 'Featured',
    2: 'Vertical',
    3: 'Event',
    4: 'Image Bank',
    5: 'Materials',
    6: 'Marketing Calendar',
    7: 'Institutional',
    8: 'Sales Tools',
    9: 'Ticket Opening',
  },
  'pt': {
    1: 'Destaque',
    2: 'Vertical',
    3: 'Evento',
    4: 'Banco de Imagens',
    5: 'Materiais',
    6: 'Calendário de marketing',
    7: 'Institucional',
    8: 'Ferramentas de vendas',
    9: 'Abertura de chamados',
  }
}
