<template>
  <f-card>
    <v-data-table :headers="headers" :items="items" @click:row="edit">
      <template v-slot:[`item.original_created_at`]="{ item }">
        {{ item.created_at }}
      </template>
      <template v-slot:[`item.remove`]="{ item }" class="ap-btns-icons">
        <div
          :class="{
            waiting: item.state == 0,
            loading: item.state == 1,
            approved: item.state == 2,
          }"
        >
          <button class="btn-approval none" @click.stop="remove(item)">
            <v-icon>mdi-close</v-icon>
          </button>
        </div>
      </template>
    </v-data-table>
  </f-card>
</template>

<script>
import mktTypes from "../../../data/mktTypes";

export default {
  data() {
    return {
      headers: [
        {
          text: "ID",
          align: "start",
          sortable: true,
          value: "id",
        },
        {
          text: "Item",
          align: "start",
          sortable: true,
          value: "title",
        },
        {
          text: this.$t("pages.mkt.items.list.type"),
          align: "start",
          sortable: true,
          value: "type",
        },
        {
          text: this.$t("pages.mkt.items.list.createdAt"),
          value: "original_created_at",
          align: "end",
        },
        {
          text: this.$t("pages.mkt.items.list.remove"),
          value: "remove",
          align: "end",
        },
      ],
    };
  },
  computed: {
    items() {
      return this.$store.state.mkt.list.map((item) => {
        item["title"] = item.fields.title.PT;
        item["link"] = item.fields.link.PT;
        item["type"] = mktTypes[this.languages][item.type_id] || "Inválido";

        return item;
      });
    },
    loading() {
      return !this.$store.state.system.loading.done;
    },
  },
  created() {
    this.languages = localStorage.getItem("currentLang");
  },
  methods: {
    edit(item) {
      this.$router.push({
        name: "mkt-items-edit",
        params: {
          id: item.id,
        },
      });
    },
    async remove(item) {
      var response = await this.$store.dispatch("dialog/show", {
        title: "Remover",
        content: "Você deseja remover este Item?",
      });
      if (response) {
        await this.$store.dispatch("mktItems/remove", item.id);
        this.$router.go();
      }
    },
  },
};
</script>
