var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('f-card',[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.items},on:{"click:row":_vm.edit},scopedSlots:_vm._u([{key:"item.original_created_at",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.created_at)+" ")]}},{key:"item.remove",fn:function(ref){
var item = ref.item;
return [_c('div',{class:{
          waiting: item.state == 0,
          loading: item.state == 1,
          approved: item.state == 2,
        }},[_c('button',{staticClass:"btn-approval none",on:{"click":function($event){$event.stopPropagation();return _vm.remove(item)}}},[_c('v-icon',[_vm._v("mdi-close")])],1)])]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }